import { JsonArray, JsonName, deserialize } from 'tserialize';

export class SearchAddressModel {
  @JsonName() address!: string;
  @JsonName('address_full') addressFull!: string;
  @JsonName() country!: string;
  @JsonName() state!: string | null;
  @JsonName() city!: string | null;
  @JsonName() street!: string | null;
  @JsonName() house!: string | null;
  @JsonName() flat!: string | null;
  @JsonName() floor!: string | null;
  @JsonName('lat', String, Number) lat!: number | null;
  @JsonName('lon', String, Number) lon!: number | null;
  @JsonName() postcode!: string | null;

  constructor(data: Partial<SearchAddressModel>) {
    Object.assign(this, data);
  }

  static fromServer(data: unknown): SearchAddressModel {
    return deserialize(data, this);
  }
}

export type TSearchAddressModel = Omit<SearchAddressModel, 'fromServer'>;

export class SearchAddressResponseModel {
  @JsonArray(SearchAddressModel) data: Array<SearchAddressModel> = [];

  constructor(data: Partial<SearchAddressResponseModel>) {
    Object.assign(this, data);
  }

  static fromServer(data: unknown): SearchAddressResponseModel {
    return deserialize(data, this);
  }
}

import { TOption } from '_shared/types';
import { RegionModel } from 'lib';

/**
 * Маппер регионов к опциям селекта
 */
export const regionsToOption = (
  regions: Array<RegionModel>,
): Array<TOption> => {
  return regions.map((region: RegionModel) => ({
    label: region.name,
    value: region.name,
  }));
};

/**
 * Маппер городов к опциям селекта
 */
export const citiesToOptions = (cities: Array<string>): Array<TOption> => {
  return cities.map((cityItem: string) => ({
    label: cityItem,
    value: cityItem,
  }));
};

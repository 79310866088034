import { DateTime } from 'luxon';

export class DateSerializers {
  static serialize(value: DateTime): string {
    return value.toISODate();
  }

  static deserialize(value: string): DateTime {
    return DateTime.fromJSDate(new Date(value));
  }
}

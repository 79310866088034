import { HttpClient } from '../http-client';
import { ContactRequest } from '../models';

export class ContactTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Отправка формы связи
   */
  async sendQuestion(request: ContactRequest): Promise<boolean> {
    const response = await this.httpClient.post('/contact', request);
    return response?.status;
  }
}

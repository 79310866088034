'use client';
import { isBrowser } from '@brand/utils';
import { IS_PRODUCTION } from '_shared/constants';
import Script from 'next/script';

const isScriptShow = (): boolean => isBrowser() && IS_PRODUCTION;

export function ScriptYaMetrika(): JSX.Element | null {
  if (isScriptShow() && !window.ym) {
    return (
      <Script
        id="YaMetrika"
        async
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            ym(52951321, "init", {clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, ecommerce:"dataLayer"})
            `,
        }}
      />
    );
  }
  return null;
}

export function ScriptVK(): JSX.Element | null {
  if (isScriptShow() && !window.VK)
    return (
      <Script
        id="VKPixel"
        async
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({id: "3326727", type: "pageView", start: (new Date()).getTime()});
        (function (d, w, id) {
          if (d.getElementById(id)) return;
          var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
          ts.src = "https://top-fwz1.mail.ru/js/code.js";
          var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
          if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
        })(document, window, "tmr-code");
        `,
        }}
      />
    );
  return null;
}

export function ScriptGA(): JSX.Element | null {
  if (isScriptShow() && !window.ga)
    return (
      <Script
        id="GA"
        async
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-5W8ZVG');
          `,
        }}
      />
    );
  return null;
}

import { HttpClient, HttpClientResponse, RegionModel } from 'lib';

import {
  SearchAddressModel,
  SearchAddressResponseModel,
} from 'lib/models/search-address.response';
import { TCountry } from 'modules/cart';
import { NetworkError } from '../errors/network-error';

/**
 * Транспорт гео данных
 */
export class GeoTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Получить регионы доставки
   */
  async getRegions(): Promise<Array<RegionModel>> {
    try {
      const response = await this.httpClient.get<{ data: Array<object> }>(
        '/geo/get_regions',
      );

      const regions = response.data.data;

      if (Array.isArray(regions)) {
        return regions.map((region: object) => RegionModel.fromServer(region));
      } else {
        throw new NetworkError('Не удалось запросить регионы доставки');
      }
    } catch (error) {
      throw new NetworkError('Не удалось запросить регионы доставки');
    }
  }

  /**
   * Получить города в регионе по поисковому запросу
   */
  async searchCityByRegionName(
    region: string,
    query: string,
  ): Promise<Array<string>> {
    try {
      const response = await this.httpClient.get<{ data: Array<string> }>(
        '/geo/get_cities',
        {
          params: {
            region,
            query,
          },
        },
      );

      const cities = response.data.data;

      if (Array.isArray(cities)) {
        return cities;
      } else {
        return [];
      }
    } catch (error) {
      throw new NetworkError('Не удалось запросить города доставки');
    }
  }

  /**
   * Получить страны по имени
   */
  async searchCountryByName(query: string): Promise<Array<TCountry>> {
    try {
      const response = await this.httpClient.get<{ data: Array<TCountry> }>(
        '/geo/get_countries',
        { params: { query } },
      );

      const countries = response.data.data;

      if (Array.isArray(countries)) {
        return countries;
      } else {
        return [];
      }
    } catch (error) {
      throw new NetworkError('Не удалось запросить города доставки');
    }
  }

  /**
   * Получить адрес названию
   */
  async searchAddress(query: string): Promise<Array<SearchAddressModel>> {
    try {
      const { data } = await this.httpClient.get<HttpClientResponse<unknown>>(
        '/geo/search_address',
        { params: { query } },
      );

      return SearchAddressResponseModel.fromServer(data).data;
    } catch (error) {
      throw new NetworkError('Не удалось запросить города доставки');
    }
  }
}

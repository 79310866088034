type TOptions = {
  domain?: string;
  path?: string;
  expires?: Date | string;
  secure?: boolean;
  ['max-age']?: number;
  ['same-site']?: boolean;
};
export class CookieClient {
  static setCookie(name: string, value: string, options: TOptions = {}): void {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);

    // eslint-disable-next-line guard-for-in
    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      // @ts-ignore
      const optionValue = options[optionKey] as string | number | boolean;
      if (optionValue !== true) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        updatedCookie += `=${optionValue}`;
      }
    }

    document.cookie = updatedCookie;
  }

  static getCookie(name: string): string | void {
    const match = new RegExp('(^| )' + name + '=([^;]+)').exec(document.cookie);
    if (match) {
      return match[2];
    }
  }

  static deleteCookie(name: string): void {
    this.setCookie(name, '', {
      'max-age': -1,
    });
  }
}

import { JsonNameReadonly, JsonStruct, deserialize } from 'tserialize';
import { Profile } from './profile.model';

export class RegisterProperties {
  /** Токен */
  @JsonNameReadonly() token!: string;

  /** Профиль пользователя */
  @JsonStruct(Profile) user!: Profile;

  /** Время жизни токена */
  @JsonNameReadonly('expires_in') expiresIn!: number;
}

/**
 * Модель ответа регистрации
 */
export class RegisterResponse {
  /** Профиль пользователя */
  @JsonStruct(RegisterProperties) data!: RegisterProperties;

  /** Сообщение */
  @JsonNameReadonly() message!: string;

  /** Статус */
  @JsonNameReadonly() status!: boolean;

  static fromServer(data: unknown): RegisterResponse {
    return deserialize(data, this);
  }
}

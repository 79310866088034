import { HttpClient } from 'lib/http-client';
import { RegionModel } from 'lib/models';
import { SearchAddressModel } from 'lib/models/search-address.response';
import { GeoTransport } from 'lib/transports/geo.transport';
import { TCountry } from 'modules/cart';

/**
 * Сервис гео данных
 */
class GeoService {
  private transport: GeoTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new GeoTransport(httpClient);
  }

  /**
   * Получить регионы доставки
   */
  getRegions(): Promise<Array<RegionModel>> {
    return this.transport.getRegions();
  }

  /**
   * Получить города в регионе по поисковому запросу
   */
  searchCityByRegionName(
    region: string,
    query: string,
  ): Promise<Array<string>> {
    return this.transport.searchCityByRegionName(region, query);
  }

  /**
   * Получить страны
   */
  searchCountryByName(query: string): Promise<Array<TCountry>> {
    return this.transport.searchCountryByName(query);
  }

  /**
   * Получить адресс
   */
  searchAddress(query: string): Promise<Array<SearchAddressModel>> {
    return this.transport.searchAddress(query);
  }
}

export const geoService = new GeoService(new HttpClient());

'use client';
import { useCallback, useEffect, useState } from 'react';

/**
 * Хук повзоляет перекидывать информацию между вкладками
 * @param channel - Название канала
 * @param subscribe  - Функция подписки
 * @returns dispatcher
 */
export function useBroadcast<T extends object>(
  channel: string,
  subscribe?: (data: T) => void,
): (data?: T) => void {
  const [broadcast] = useState(() => createBroadcastChannel(channel));
  const dispatch = useCallback((data?: T) => {
    broadcast.postMessage(data);
  }, []);

  useEffect(() => {
    broadcast.addEventListener('message', (event: MessageEvent) => {
      subscribe?.(event.data as T);
    });
    return () => {
      broadcast.close();
    };
  }, []);

  return dispatch;
}

type BroadcastChannelType = {
  postMessage: (data: unknown) => void;
  addEventListener: (
    event: 'message',
    listener: (event: MessageEvent) => void,
  ) => void;
  close: () => void;
  onmessage: unknown;
};

function createBroadcastChannel(channel: string): BroadcastChannelType {
  if (typeof BroadcastChannel !== 'undefined') {
    return new BroadcastChannel(channel) as BroadcastChannelType;
  } else {
    const listeners: Array<(event: MessageEvent) => void> = [];
    const onStorage = (event: StorageEvent): void => {
      if (event.key === channel) {
        const data = localStorage.getItem(channel);
        listeners.forEach((listener: (event: MessageEvent) => void) =>
          listener({ data } as unknown as MessageEvent),
        );
      }
    };

    window.addEventListener('storage', onStorage);

    return {
      postMessage: (data: unknown) => {
        localStorage.setItem(channel, JSON.stringify(data));
        localStorage.removeItem(channel);
      },
      addEventListener: (
        event: 'message',
        listener: (event: MessageEvent) => void,
      ) => {
        if (event === 'message') {
          listeners.push(listener);
        }
      },
      set onmessage(onMessageHandler: (event: unknown) => void) {
        listeners.push(onMessageHandler);
      },
      close: () => {
        window.removeEventListener('storage', onStorage);
      },
    };
  }
}

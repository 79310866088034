import { captureException } from '@sentry/nextjs';
import { OAuthProvider } from 'lib/models/oaut.model';
import { RefreshTokenResponse } from 'lib/models/refresh-token.response';
import {
  HttpClient,
  HttpClientResponse,
  isHttpClientError,
} from '../http-client';
import {
  CheckResponse,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
} from '../models';

/**
 * Транспорт авторизации
 */
export class AuthTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Регистрация
   */
  async register(
    request: RegisterRequest,
  ): Promise<RegisterResponse | undefined> {
    try {
      const response = await this.httpClient.post('/auth/register', request);
      return RegisterResponse.fromServer(response.data);
    } catch (error: unknown) {
      if (isHttpClientError(error)) throw new Error(error.message);
    }
  }

  /**
   * Получение токена по логину и паролю
   */
  async login(params: {
    email: string;
    password: string;
  }): Promise<LoginResponse | undefined> {
    const request = { email: params.email, password: params.password };

    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        '/auth/login',
        request,
      );

      return LoginResponse.fromServer(response.data.data);
    } catch (error) {
      throw new Error('Не удалось авторизовать пользователя');
    }
  }

  /**
   * Авторизация с помощью сервисов (tochkaID)
   */
  async oath({
    provider = 'tochka',
    code,
    redirectUri,
  }: {
    provider?: OAuthProvider;
    code: string;
    redirectUri: string;
  }): Promise<LoginResponse | undefined> {
    const request = { code, redirect_uri: redirectUri };

    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        `/oauth/${provider}/authenticate`,
        request,
      );

      return LoginResponse.fromServer(response.data.data);
    } catch (error) {
      throw new Error('Не удалось авторизовать пользователя');
    }
  }

  /**
   * Проверка валидности токена
   */
  async check(token: string): Promise<CheckResponse> {
    const request = { token };

    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        '/auth/check',
        request,
      );

      return CheckResponse.fromServer(response.data);
    } catch (error) {
      throw new Error('Не удалось провалидировать токен');
    }
  }

  /**
   * Обновление токена
   */
  async refresh(token: string): Promise<RefreshTokenResponse> {
    const request = { token };

    try {
      const { data } = await this.httpClient.post<
        HttpClientResponse<RefreshTokenResponse>
      >('/auth/refresh', request);
      return RefreshTokenResponse.fromServer(data.data);
    } catch (error) {
      captureException(error);
      throw new Error(`Не удалось обновить токен`);
    }
  }

  /**
   * Восстановить пароль
   */
  async restorePassword(email: string): Promise<void> {
    const request = { email };

    try {
      const response = await this.httpClient.post<HttpClientResponse>(
        '/auth/restore_password',
        request,
      );
      if (!response.data.status) {
        throw new Error('Не удалось восстановить пароль');
      }
      return void 0;
    } catch (error) {
      throw new Error('Не удалось восстановить пароль');
    }
  }

  /**
   * Сбросить пароль
   */
  async resetPassword(
    slug: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<void> {
    const request = {
      slug,
      password,
      password_confirmation: passwordConfirmation,
    };

    try {
      const response = await this.httpClient.post<HttpClientResponse>(
        '/auth/reset_password',
        request,
      );

      if (!response.data.status) {
        throw new Error('Не удалось сбросить пароль');
      }

      return void 0;
    } catch (error) {
      throw new Error(`Не удалось сбросить пароль`);
    }
  }
}

import { deserialize, JsonNameReadonly } from 'tserialize';

/**
 * Модель ответа проверки валидности токена
 */
export class CheckResponse {
  /** Действительный ли токен */
  @JsonNameReadonly() status!: boolean;

  /** Данные */
  @JsonNameReadonly() data!: string;

  /** Сообщение */
  @JsonNameReadonly() message!: string;

  /** Код */
  @JsonNameReadonly() code!: string;

  static fromServer(data: object): CheckResponse {
    return deserialize(data, this);
  }
}

import { HttpClient } from 'lib/http-client';
import { OAuthProvider } from 'lib/models/oaut.model';
import { PubSub } from 'lib/utils/pub-sub';
import { DateTime } from 'luxon';
import { CookieService } from './cookie.service';
import {
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
  TRegisterRequest,
} from '../models';
import { AuthTransport } from '../transports';

export type TAuthServiceEvents = 'logout';
/**
 * Сервис авторизации
 * TODO: добавить работу с csrf токеном
 * Умеет отправлять запросы как с клиента, так и во время ssr
 */
export class AuthService extends PubSub<TAuthServiceEvents> {
  private static COOKIE_NAME = 'Authorization';

  private transport: AuthTransport;

  constructor(httpClient: HttpClient) {
    super();
    this.transport = new AuthTransport(httpClient);
  }

  getToken = (): string => {
    return CookieService.getValue(AuthService.COOKIE_NAME);
  };

  setToken = (token: string): void => {
    const expires = DateTime.local().plus({ year: 1 }).toJSDate();

    CookieService.setValue(AuthService.COOKIE_NAME, token, { expires });
  };

  cleanToken(): void {
    CookieService.removeValue(AuthService.COOKIE_NAME);
  }

  /**
   * Авторизация
   */
  async login(
    email: string,
    password: string,
  ): Promise<LoginResponse | undefined> {
    const response = await this.transport.login({ email, password });

    if (!response) return;
    this.setToken(response.token);
    return response;
  }

  /**
   * Авторизация с помощью сервисов (tochkaID)
   */
  async oauth({
    provider = 'tochka',
    code,
    redirectUri,
  }: {
    provider?: OAuthProvider;
    code: string;
    redirectUri: string;
  }): Promise<LoginResponse | undefined> {
    const response = await this.transport.oath({
      provider,
      code,
      redirectUri,
    });
    if (!response) return;
    this.setToken(response.token);
    return response;
  }

  /**
   * Проверка валидности токена
   */
  async check(initToken?: string): Promise<boolean> {
    const token = initToken || this.getToken();

    if (!token) return false;

    const response = await this.transport.check(token);

    return response?.status ?? false;
  }

  /**
   * Обновление токена
   */
  async refreshToken(): Promise<void> {
    const currentToken = this.getToken();
    try {
      this.cleanToken();
      const { token } = await this.transport.refresh(currentToken);
      this.setToken(token);
    } catch (error: unknown) {
      this.emit('logout');
      throw error;
    }
  }

  /**
   * Запрос на смену пароля
   */
  async restorePassword(email: string): Promise<void> {
    return this.transport.restorePassword(email);
  }

  /**
   * Сбросить пароль
   *
   * slug берем из localtion из query param "code"
   */
  async resetPassword(
    slug: string,
    password: string,
    passwordConformation: string,
  ): Promise<void> {
    return this.transport.resetPassword(slug, password, passwordConformation);
  }

  async register(
    data: TRegisterRequest,
  ): Promise<RegisterResponse | undefined> {
    const request = new RegisterRequest(data);
    const response = await this.transport.register(request.toServer());

    if (!response?.data) return;
    this.setToken(response.data.token);
    return response;
  }
}

export const authService = new AuthService(new HttpClient());

import { serialize, JsonName } from 'tserialize';

export type TContactRequest = Omit<ContactRequest, 'toServer'>;

export class ContactRequest {
  /** Email */
  @JsonName() email!: string;

  /** Тема письма */
  @JsonName() subject?: string;

  /** Имя отправителя */
  @JsonName() name!: string;

  /** Комментарий/вопрос */
  @JsonName() comment!: string;

  constructor(data: TContactRequest) {
    Object.assign(this, data);
  }

  toServer(): object {
    return serialize(this);
  }
}

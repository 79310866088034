export enum Count {
  ZERO = 'zero',
  ONE = 'one',
  FEW = 'few',
  MANY = 'many',
}

export type TFormatOptions = {
  zero?: string;
  one: string;
  few: string;
  many: string;
};

export const COUNT: TFormatOptions = {
  zero: 'штук',
  one: 'штука',
  few: 'штуки',
  many: 'штук',
};

function prepare(value: number): Count {
  if (value === 0) {
    return Count.ZERO;
  }

  let countCopy = Math.abs(value);
  countCopy %= 100;
  if (countCopy >= 5 && countCopy <= 20) {
    return Count.MANY;
  }
  countCopy %= 10;
  if (countCopy === 1) {
    return Count.ONE;
  }
  if (countCopy >= 2 && countCopy <= 4) {
    return Count.FEW;
  }

  return Count.MANY;
}

export function format(value: number, options: TFormatOptions): string {
  const category: Count = prepare(value);
  const template: string = options[category] ?? options[Count.ONE];

  return template.replace(/{}/g, String(value));
}

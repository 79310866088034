import { DateTime } from 'luxon';
import { DeliveryPoint } from 'modules/cart/api/models/delivery-points-response.model';
import { deserialize, JsonArray, JsonName, JsonStruct } from 'tserialize';
import { Office } from './office-response.model';
import { MoneyFormatter } from '../formatters';
import { DateSerializers } from '../serializers';

export class OrderCoupon {
  /** Промокод */
  @JsonName() code: string | null = null;
  /** Сумма скидки */
  @JsonName('discount_price_value') discountPriceValue: number | null = null;
}

export class OrderOfferProperty {
  @JsonName() code!: string;
  @JsonName() description!: string;
  @JsonName() name!: string;
  @JsonName() value!: string;
}

export class OrderOfferPosition {
  /** Название товара с учетом свойств */
  @JsonName() name!: string;
  /** Свойства товара */
  @JsonArray(OrderOfferProperty) property: Array<OrderOfferProperty> = [];
}

export class OrderPosition {
  /** Слаг */
  @JsonName() slug!: string;
  /** Название товара */
  @JsonName() name!: string;
  /** Детали */
  @JsonStruct(OrderOfferPosition) offer!: OrderOfferPosition;
  /** Превью товара */
  @JsonName('preview_image') previewImage!: string;
  /** Старая цена */
  @JsonName('old_price_value')
  oldPriceValue!: number;
  /** Текущая цена */
  @JsonName('price_value') priceValue!: number;
  /** Итоговая цена без учета скидок */
  @JsonName('old_total_price_value') oldTotalPriceValue!: number;
  /** Итоговая цена */
  @JsonName('total_price_value') totalPriceValue!: number;
  /** Количество */
  @JsonName('quantity') quantity!: number;
}

export class OrderStatus {
  /** Цвет статуса заказа */
  @JsonName('color') color!: string;

  /** Название статуса */
  @JsonName('name') name!: string;
}

export class PaymentMethod {
  /** Способ оплаты */
  @JsonName('name') name!: string;
}

export class OrderProperties {
  /** Адрес получателя */
  @JsonName('shipping_address1') address!: string;

  /** Город получателя */
  @JsonName('shipping_city') city!: string;

  /** Регион получателя */
  @JsonName('shipping_state') state!: string;

  /** Имя получателя заказа */
  @JsonName('name') firstName!: string;

  /** Фамилия получателя заказа */
  @JsonName('last_name') lastName!: string;
}

export class OrderDeliveryTrack {
  /**
   * Номер отслеживания
   */
  @JsonName() number!: string;
  /**
   * Ссылка отслеживания
   */
  @JsonName() link!: string;
}

export class OrderDeliveryCourier {
  @JsonName() address: string | null = null;
}
/**
 * Детали доставки
 * в зависимости от выбранного типа доставки приходит одно из полей point|office|courier
 */
export class OrderDelivery {
  /**
   * Детали для отслеживание отправления
   */
  @JsonStruct(OrderDeliveryTrack)
  track!: OrderDeliveryTrack;

  /**
   * Пункт выдачи
   */
  @JsonStruct(DeliveryPoint, 'delivery_point')
  point!: DeliveryPoint | null;

  /**
   * Офис выдачи
   */
  @JsonStruct(Office, 'delivery_office')
  office!: Office | null;
  /**
   * Адрес для доставки курьером
   */
  @JsonStruct(OrderDeliveryCourier, 'delivery_courier')
  courier!: OrderDeliveryCourier | null;
}

export class Order {
  /** ID заказа */
  @JsonName() id!: number;

  /** Дата создания заказа */
  @JsonName('created_at', undefined, DateSerializers.deserialize)
  createdAt!: DateTime;

  /** Номер заказа */
  @JsonName('order_number') number!: string;

  /** Итоговая сумма заказа с учётом доставки и всех скидок */
  @JsonName('total_price_value') total!: number;

  /** Символ валюты для сумм в заказе */
  @JsonName('currency_symbol') currencyCode!: MoneyFormatter.Currency;

  /** Флаг ожидает ли заказ оплаты */
  @JsonName('waiting_payment') isWaitingPayment!: boolean;

  /** Статус заказа */
  @JsonStruct(OrderStatus, 'status') status!: OrderStatus;

  /** Товары в заказе */
  @JsonArray(OrderPosition) positions: Array<OrderPosition> = [];

  /** Примененный купон */
  @JsonStruct(OrderCoupon) coupon!: OrderCoupon;

  /** Способ оплаты */
  @JsonStruct(PaymentMethod, 'payment_method')
  private paymentMethodData!: PaymentMethod;

  /** Свойства заказа */
  @JsonStruct(OrderProperties, 'property')
  private properties!: OrderProperties;

  /** Детали доставки */
  @JsonStruct(OrderDelivery)
  delivery!: OrderDelivery;

  get paymentMethod(): string {
    return this.paymentMethodData.name;
  }

  get deliveryAddress(): string {
    return `${this.properties.state}, ${this.properties.city}, ${this.properties.address}`;
  }

  get recipient(): string {
    return `${this.properties.firstName} ${this.properties.lastName}`;
  }

  constructor(data: Partial<Order>) {
    Object.assign(this, data);
  }

  static fromServer(data: unknown): Order {
    return deserialize(data, this);
  }
}

export type TOfferResponse = Omit<Order, 'fromServer'>;

import {
  TCarrotEventCallbackData,
  TCarrotEvents,
  TCarrotQuest,
  TOpt,
} from './carrotquest.types';
import { CookieClient } from '../../utils/cookie-client';

export type TCarrotQookies = {
  carrotquest_auth_token: string | void;
  carrotquest_device_guid: string | void;
  carrotquest_realtime_services_transport: string | void;
  carrotquest_session: string | void;
  carrotquest_session_started: string | void;
  carrotquest_uid: string | void;
};

export class CarrotQuest {
  carrot: TCarrotQuest | undefined;
  callbacks: Set<(data: TCarrotEventCallbackData) => void> = new Set();

  constructor() {
    if (typeof window !== 'undefined') {
      void this.init();
    }
  }

  /**
   * Инициализация qarrotquest
   */
  async init(): Promise<void> {
    this.carrot = await carrotScriptInitializer(
      '56391-d83de88951a7b7eef958d7138c',
    );

    this.registerCallbacks();
  }

  /**
   * Уничтожение экземпляра
   */
  destroy(): void {
    delete window.carrotquest;
    delete window.carrrot;
    delete window.carrotquestasync;
    this.clearCookies();
  }

  clearCookies(): void {
    CookieClient.deleteCookie('carrotquest_auth_token');
    CookieClient.deleteCookie('carrotquest_device_guid');
    CookieClient.deleteCookie('carrotquest_realtime_services_transport');
    CookieClient.deleteCookie('carrotquest_session');
    CookieClient.deleteCookie('carrotquest_session_started');
    CookieClient.deleteCookie('carrotquest_uid');
  }

  /**
   * Возвращает все куки
   * Полезно для востановления сессий
   */
  getCookies(): TCarrotQookies {
    const carrotquest_auth_token = CookieClient.getCookie(
      'carrotquest_auth_token',
    );
    const carrotquest_device_guid = CookieClient.getCookie(
      'carrotquest_device_guid',
    );
    const carrotquest_realtime_services_transport = CookieClient.getCookie(
      'carrotquest_realtime_services_transport',
    );
    const carrotquest_session = CookieClient.getCookie('carrotquest_session');
    const carrotquest_session_started = CookieClient.getCookie(
      'carrotquest_session_started',
    );
    const carrotquest_uid = CookieClient.getCookie('carrotquest_uid');

    return {
      carrotquest_auth_token,
      carrotquest_device_guid,
      carrotquest_realtime_services_transport,
      carrotquest_session,
      carrotquest_session_started,
      carrotquest_uid,
    };
  }

  /**
   * Устанавливает куки для работы кэрота
   * @param params {TCarrotQuest}
   */
  setCookies(params: TCarrotQookies): void {
    Object.entries(params).forEach(([key, value]: [string, string | void]) => {
      CookieClient.setCookie(key, value ?? '', { 'max-age': 10 });
    });
  }

  /**
   * Авторизация пользователя
   * @param userId {String} = любой идентификатор id, email и тд
   * @param hash = сгенерированный хэг
   */
  auth(userId: string, hash: string): void {
    try {
      this.carrot?.auth(userId, hash);
    } catch (error: unknown) {
      console.error('Авторизация не удалась', error);
    }
  }

  /**
   * Отслеживание событий
   * */
  track(event: TCarrotEvents, payload?: object): void {
    try {
      this.carrot?.track?.(event, payload);
    } catch (error: unknown) {
      console.log(error);
    }
  }

  /**
   * Запись свойств
   * */
  identify(opts: Array<TOpt> | object): void {
    try {
      this.carrot?.identify?.(opts);
    } catch (error: unknown) {
      console.log(error);
    }
  }

  /**
   * Добавляет новый callback на событие
   *
   * TODO: описать все типы data по сообщениям
   */
  addCallback(
    event: TCarrotEvents,
    callback: (data: TCarrotEventCallbackData) => void,
  ): void {
    try {
      if (!this.carrot) {
        this.callbacks.add(callback);
        return;
      }
      this.carrot?.addCallback?.('event_tracked', callback);
    } catch (error: unknown) {
      console.log(error);
    }
  }

  /**
   * Удаляет callback на событие
   *
   * TODO: описать все типы data по сообщениям
   */
  removeCallback(
    event: TCarrotEvents,
    callback: (data: TCarrotEventCallbackData) => void,
  ): void {
    try {
      if (!this.carrot) {
        this.callbacks.delete(callback);
        return;
      }
      this.carrot?.removeCallback?.(event, callback);
    } catch (error: unknown) {
      console.log(error);
    }
  }

  /**
   * Регистрирует накопленные до инициализации кэрота колбеки
   */
  registerCallbacks(): void {
    Array.from(this.callbacks).forEach(
      (callback: (data: TCarrotEventCallbackData) => void) =>
        this.carrot?.addCallback('event_tracked', callback),
    );
  }
}

/**
 * Скрипт для добавления carrot в приложение
 */
function carrotScriptInitializer(apiKey: string): Promise<TCarrotQuest> {
  return new Promise((resolve: (carrot: TCarrotQuest) => void) => {
    function t(first: unknown) {
      return function () {
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        window.carrotquestasync.push(first, arguments);
      };
    }

    if ('undefined' === typeof window.carrotquest) {
      const e = document.createElement('script');
      // eslint-disable-next-line no-sequences
      (e.type = 'text/javascript'),
        (e.async = !0),
        (e.src =
          'https://tochka.com/storage/app/media/carrotquest/carrotquest.js'),
        document.getElementsByTagName('head')[0].appendChild(e),
        (window.carrotquest = {}),
        (window.carrotquestasync = []),
        ((window.carrotquest as Record<string, unknown>).settings = {});
      for (
        let n = [
            'connect',
            'track',
            'identify',
            'auth',
            'onReady',
            'addCallback',
            'removeCallback',
            'trackMessageInteraction',
          ],
          a = 0;
        a < n.length;
        a++
      )
        // @ts-ignore
        window.carrotquest[n[a]] = t(n[a]);
    }
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.carrotquest.connect(apiKey);
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.carrotquest.onReady(() => {
      resolve(window.carrotquest as TCarrotQuest);
    });
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.carrotquest.track('$page_view', {
      current_url: location.href,
      current_page: document.title,
    });
  });
}

import { deserialize, JsonNameReadonly } from 'tserialize';

/**
 * Модель ответа логина
 */
export class RefreshTokenResponse {
  /** Токен */
  @JsonNameReadonly() token!: string;

  /** Время жизни токена */
  @JsonNameReadonly('expires_in') expiresIn!: number;

  static fromServer(data: unknown): RefreshTokenResponse {
    return deserialize(data, this);
  }
}

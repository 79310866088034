import { ApplicationError } from './application-error';

export class NetworkError extends ApplicationError {
  name = 'NetworkError';
}

export type TError = {
  error?: string;
};

type TErrorWithMessage = {
  message: string;
};

export const isErrorWithMessage = (
  error: unknown,
): error is TErrorWithMessage => {
  return (
    !!error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  );
};

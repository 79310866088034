import { deserialize, JsonArray, JsonName, JsonStruct } from 'tserialize';
import { Product, TProductResponse } from './product.model';

export class PaginationLinks {
  @JsonName() first!: string | null;

  @JsonName() last!: string | null;

  @JsonName() prev!: string | null;

  @JsonName() next!: string | null;
}

export class PaginationMeta {
  @JsonName('current_page') currentPage!: number;

  @JsonName('per_page') perPage!: number;

  @JsonName('last_page') lastPage!: number;

  @JsonName() total!: number;
}

export type TProductsListResponse = Omit<ProductsResponseModel, 'fromServer'>;

export class ProductsResponseModel {
  @JsonArray(Product) data: Array<TProductResponse> = [];

  @JsonStruct(PaginationLinks) links!: PaginationLinks;

  @JsonStruct(PaginationMeta) meta!: PaginationMeta;

  /** Позволяет десериализовать модель ответа от бекенда */
  static fromServer(data: object): ProductsResponseModel {
    return deserialize(data, this);
  }
}

import {
  HttpClientError,
  HttpClientResponse,
  HttpClient as HttpClientUtility,
  HttpStatusCode as HttpCodes,
  THttpClientConfig,
  TRequestConfig,
  isHttpClientError,
  pipe,
} from '@brand/utils';
import { captureException } from '@sentry/nextjs';
import { getRevalidateTime } from '_shared/constants/revalidate';
import { authService } from 'lib';

const DEFAULT_OPTIONS: THttpClientConfig = {
  credentials: 'same-origin',
  baseURL: `${process.env.SERVER_BACKEND_API_HOST ?? ''}/api/v1`,
};

const randomizeNextRevalidateTime = (
  config: TRequestConfig,
): TRequestConfig => {
  if (!config.options.next) {
    config.options.next = {};
  }

  config.options.next.revalidate = getRevalidateTime();
  return config;
};

const setAuthorizationHeader = (config: TRequestConfig): TRequestConfig => {
  const token = authService.getToken();
  if (!config.options.headers) {
    config.options.headers = new Headers();
  }
  if (token) {
    config.options.headers.set('Authorization', `Bearer ${token}`);
  }
  return config;
};

const setAcceptJson = (config: TRequestConfig): TRequestConfig => {
  if (!config.options.headers) {
    config.options.headers = new Headers();
  }
  if (!config.options.headers.get('Accept')) {
    config.options.headers.set('Accept', 'application/json');
  }

  return config;
};

const sendSentryNotify = (error: HttpClientError): void => {
  if (
    [
      HttpCodes.BAD_GATEWAY,
      HttpCodes.SERVICE_UNAVAILABLE,
      HttpCodes.NOT_FOUND,
    ].includes(error.code)
  ) {
    captureException(error);
  }
};

const isNeedRefreshToken = (
  error: HttpClientError,
  isRefreshing: boolean,
): boolean =>
  isRefreshing ||
  (error.code === HttpCodes.UNAUTHORIZED &&
    !!error.request.options.headers?.get('Authorization'));
let isRefreshing = false;
let requestQueue: Array<() => void> = [];
export class HttpClient extends HttpClientUtility {
  constructor(config?: THttpClientConfig) {
    super(config ?? DEFAULT_OPTIONS);

    this.interceptors.request = pipe(
      setAcceptJson,
      setAuthorizationHeader,
      randomizeNextRevalidateTime,
    );

    this.interceptors.error = async (
      error: HttpClientError,
    ): Promise<unknown> => {
      sendSentryNotify(error);

      if (isRefreshing && !error.request.url.includes('refresh')) {
        return new Promise(
          (resolve: (value: unknown) => void, reject: () => void): void => {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            requestQueue.push(() =>
              error.refetch().then(resolve).catch(reject),
            );
          },
        );
      }

      if (isNeedRefreshToken(error, isRefreshing)) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            await authService.refreshToken();
            isRefreshing = false;
            const queuedRequests = [...requestQueue];
            requestQueue = [];
            queuedRequests.forEach((callback: VoidFunction) => callback());
            return error.refetch();
          } catch (refreshError) {
            isRefreshing = false;
            throw refreshError;
          }
        }
      } else {
        throw error;
      }
    };
  }
}

export const httpClient = new HttpClient(DEFAULT_OPTIONS);
export { HttpClientError, HttpCodes, isHttpClientError };
export type { HttpClientResponse, THttpClientConfig, TRequestConfig };

export enum Sign {
  PLUS = '+\u00a0',
  MINUS = '\u2013\u00a0',
  EMPTY = '',
}

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
}

export const SYMBOLS = {
  [Currency.RUB]: '\u20bd',
  [Currency.USD]: '\u0024',
  [Currency.EUR]: '\u20ac',
};

type TPrepareOptions = {
  withZeroFraction?: boolean;
};

type TFormatOptions = {
  currency?: Currency;
  sign?: Sign;
  withZeroFraction?: boolean;
};

function prepare(value: number, options?: TPrepareOptions): string {
  const withZeroFraction = options?.withZeroFraction ?? false;
  const digits = 2;
  const moneyString =
    withZeroFraction && Number.isInteger(value) ? `${value}.00` : String(value);
  let [integer, fraction = ''] = moneyString.split('.');
  if (fraction) {
    fraction = (fraction + '0').slice(0, digits);
    fraction = !withZeroFraction && fraction === '00' ? '' : ',' + fraction;
  }
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(integer)) {
    integer = integer.replace(rgx, '$1\u00a0$2');
  }

  return `${integer}${fraction}`;
}

export function format(value: number, options?: TFormatOptions): string {
  const currency = options?.currency ?? Currency.RUB;
  const withZeroFraction = options?.withZeroFraction ?? false;
  const sign = options?.sign ?? Sign.EMPTY;

  const fSign = value < 0 ? Sign.MINUS : sign;
  const fMoney = Math.abs(value);

  const moneyString = prepare(fMoney, { withZeroFraction });
  const currencyString = Currency[currency];

  return `${fSign}${moneyString}\u00a0${SYMBOLS[currencyString]}`;
}

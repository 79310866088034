import { HttpClient, HttpClientResponse } from '../http-client';
import { CertificateRequest } from '../models';
import { CertificateResponse } from '../models/certificate.response';
import { CertificatesResponse } from '../models/certificates.response';

/**
 * Транспорт сертификатов
 */
export class CertificateTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Отправка сертификата
   */
  async sendCertificate(
    request: CertificateRequest,
  ): Promise<CertificateResponse> {
    try {
      const response = await this.httpClient.post('/gift_card/order', request, {
        baseURL: '/api/v2',
      });

      return CertificateResponse.fromServer(response.data);
    } catch (error) {
      throw new Error(`Не удалось отправить сертификат`);
    }
  }

  /**
   * Получение списка сертификатов
   */
  async getCertificatesList(): Promise<CertificatesResponse | undefined> {
    try {
      const response = await this.httpClient.get<HttpClientResponse<object>>(
        '/gift_card/get',
      );
      const { data } = response.data;

      if (Array.isArray(data)) {
        return JSON.parse(JSON.stringify(data)) as CertificatesResponse;
      }
    } catch (error) {
      console.error(error);
    }
  }
}

import { JsonName, serialize } from 'tserialize';

export type TSearchProductsRequest = Omit<SearchProductsRequest, 'toServer'>;
const DEFAULT_SEARCH_PRODUCTS_LIMIT = 20;

export class SearchProductsRequest {
  @JsonName() search!: string;

  @JsonName() limit: number = DEFAULT_SEARCH_PRODUCTS_LIMIT;

  @JsonName('fields') fields?: Array<string>;

  @JsonName('filter[is_available]', (value: boolean) =>
    value === undefined ? undefined : Number(value),
  )
  isAvailable?: boolean;

  @JsonName('filter[only_employees]', (value: boolean) =>
    value === undefined ? undefined : Number(value),
  )
  onlyEmployees?: boolean;

  constructor(data: TSearchProductsRequest) {
    Object.assign(this, data);
  }

  toServer(): TSearchProductsRequest {
    return serialize(this) as SearchProductsRequest;
  }
}

import { isObject } from '@brand/utils';

/**
 * Хелпер для приведения пропса вида  string | {s: string} - TBreakpoint
 * к одному виду объекта
 * @param value
 * @param defaultValue
 * @returns
 */
export function toMediaValue<T, R = unknown>(value: R, defaultValue?: R): T {
  return (isObject(value)
    ? value
    : { s: value ?? defaultValue }) as unknown as T;
}

import { deserialize, JsonArray, JsonName } from 'tserialize';
import { Product } from './product.model';

export class Specproject {
  @JsonName() slug!: string;

  @JsonName() name!: string;

  /** Продукты */
  @JsonArray(Product) products: Array<Product> = [];

  static fromServer(data: object): Specproject {
    return deserialize(data, Specproject);
  }
}

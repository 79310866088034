import { HttpClient } from '../http-client';
import { TOpt } from '../services/carrot/carrotquest.types';

export class CarrotTransport {
  constructor(private httpClient: HttpClient) {}
  async carrotUserHash(
    userId: string,
  ): Promise<{ userId: string; hash: string }> {
    const { data } = await this.httpClient.post<{
      userId: string;
      hash: string;
    }>(
      '/carrot-user-hash/',
      { userId },
      {
        baseURL: process.env.nextApiBaseUrl,
      },
    );
    return data;
  }

  async userProps(body: {
    id: string;
    operations: Array<TOpt>;
  }): Promise<void> {
    try {
      await this.httpClient.post<void>('/carrot-user-props/', body, {
        baseURL: process.env.nextApiBaseUrl,
      });
    } catch (error: unknown) {
      console.log(error);
    }
  }
}

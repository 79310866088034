import { captureException } from '@sentry/nextjs';
import { CartPositions, GetCartResponseModel } from 'modules/cart';
import { CarrotQuest } from './carrotquest';
import {
  TCarrotEventCallbackData,
  TCarrotEvents,
  TOpt,
} from './carrotquest.types';
import { HttpClient } from '../../http-client';
import { OfferPrice, Product } from '../../models';
import { CarrotTransport } from '../../transports/carrot.transport';

type TProductActionParams = {
  /** Название товара */
  $name: string;
  /** Ссылка на страницу с описанием товара */
  $url: string;
  /** Цена товара в рублях. Целое число (если есть копейки, их необходимо округлить) */
  $amount: string;
  /** Ссылка на картинку с товаром */
  $img: string;
};

type TUpdateCarrotUserProps = {
  $email?: string | null;
  $phone?: string | null;
};

type TUpdateUserProps = {
  userId: number;
  email: string;
  phone?: string | null;
};

const LAST_CART_AMOUNT = 'LAST_CART_AMOUNT';

class CarrotService {
  private transport: CarrotTransport;
  private carrotQuest: CarrotQuest = new CarrotQuest();

  constructor(private httpClient: HttpClient) {
    this.transport = new CarrotTransport(httpClient);
  }

  async restart(): Promise<void> {
    this.carrotQuest.destroy();
    await this.carrotQuest.init();
  }
  /**
   * Идентификация польлзователя
   * @param userId
   */
  async identifyUser({
    userId,
    email: $email,
    phone: $phone,
  }: TUpdateUserProps): Promise<void> {
    try {
      const data = await this.transport.carrotUserHash(userId.toString());
      this.carrotQuest.auth(data.userId, data.hash);
      this.updateUser({ $email, $phone });
    } catch {
      captureException('Failed carrotQuest auth');
    }
  }

  /**
   * Просмотрена страница
   */
  pageView(): void {
    try {
      this.carrotQuest.track('$page_view', {
        current_url: location.href,
        current_page: document.title,
      });
    } catch {
      captureException('Failed carrotQuest auth');
    }
  }

  private updateUser(props: TUpdateCarrotUserProps): void {
    const opts = Object.entries(props).reduce(
      (acc: Array<TOpt>, [key, value]: [string, string | null]) => {
        if (value) {
          acc.push({
            op: 'update_or_create',
            value,
            key: key as TCarrotEvents,
          });
        }
        return acc;
      },
      [],
    );

    this.carrotQuest.identify(opts);
  }
  /**
   * Добавил товар в корзину
   * Системное имя: $cart_added.
   * */
  cartAdded(position: CartPositions): void {
    this.carrotQuest.track(
      '$cart_added',
      this.cartPositionToProductAction(position),
    );
    this.carrotQuest.identify([
      { op: 'union', key: '$cart_items', value: position.product.name },
      { op: 'add', key: '$cart_amount', value: position.totalValue },
    ]);
  }

  /**
   * Удалил товар из корзины
   * */
  cartRemoved(position: CartPositions): void {
    this.carrotQuest.track(
      'Удалил товар из корзины',
      this.cartPositionToProductAction(position),
    );
  }

  /**
   * Посмотрел корзину
   * Системное имя: $cart_viewed.
   * Все свойства в этом событии являются массивами.
   * Например, есть два товара с названиями А,B и ценами 10,20. Тогда в свойстве $name будет лежать A,B, а в свойстве $amount значения 10,20.
   * */
  cartViewed(cart?: GetCartResponseModel): void {
    const items =
      cart?.positions.map((position: CartPositions) => position.product.name) ??
      [];
    this.carrotQuest.track('$cart_viewed', {});
    this.carrotQuest.identify([
      {
        op: items.length === 0 ? 'delete' : 'update_or_create',
        key: '$cart_items',
        value: items.length === 0 ? 0 : items,
      },
      {
        op: cart?.totalPrice?.priceValue === 0 ? 'delete' : 'update_or_create',
        key: '$cart_amount',
        value: cart?.totalPrice?.priceValue ?? 0,
      },
    ]);
  }

  /**
   * Посмотрел товар
   * */
  productViewed(product: Product, price?: OfferPrice): void {
    this.carrotQuest.track(
      '$product_viewed',
      this.productToProductAction(product, price),
    );

    this.carrotQuest.identify([
      { op: 'union', key: '$viewed_products', value: product.name },
    ]);
  }

  /**
   * Оформлен возврат
   * Системное имя: $order_refunded.
   * @param $order_id  ID заказа
   * Событие происходит, когда клиент получил обратно оплату за заказ.
   * */
  orderRefunded($order_id: string): void {
    this.carrotQuest.track('$order_refunded', { $order_id });
  }

  /**
   * Отменил заказ
   * Событие происходит, когда клиент или магазин отменил заказ.
   * @param $order_id  ID заказа
   * @param $comment  Комментарий
   * */
  orderCancelled($order_id: string, $comment: string): void {
    this.carrotQuest.track('$order_cancelled', { $order_id, $comment });
  }

  /**
   * Получил заказ
   * Системное имя: $order_closed.
   * @param $order_id  ID заказа
   * Событие происходит, когда клиент получил заказ.
   * */
  orderClosed($order_id: string): void {
    this.carrotQuest.track('$order_cancelled', { $order_id });
  }

  /**
   * Оплатил заказ
   * Системное имя: $order_paid.
   * Событие происходит, когда клиент оплатил заказ.
   * @param $order_id 	ID заказа
   * */
  orderPaid($order_id: string): void {
    this.carrotQuest.track('$order_paid', { $order_id });
  }

  /**
   * Закончил оформлять заказ
   * Системное имя: $order_completed
   *
   * Пользователь прошел все шаги процесса чекаута, заказ создан в системе магазина.
   * @param $order_id ID заказа (внутренний)
   * */
  orderCompleted($order_id: string): void {
    const lastCartAmount = localStorage?.getItem(LAST_CART_AMOUNT);

    this.carrotQuest.track('$order_completed', {
      $order_id,
      $order_amount: lastCartAmount,
    });

    this.carrotQuest.identify([
      { op: 'delete', key: '$cart_items', value: 0 },
      { op: 'delete', key: '$viewed_products', value: 0 },
      { op: 'delete', key: '$cart_amount', value: 0 },
      { op: 'add', key: '$revenue', value: lastCartAmount || '' },
      { op: 'add', key: '$orders_count', value: 1 },
      {
        op: 'update_or_create',
        key: '$last_payment',
        value: lastCartAmount || '',
      },
    ]);
  }

  /**
   * Начал оформлять заказ
   * Системное имя: $order_started
   *
   * Это событие происходит, когда пользователь перешел на первый шаг процесса чекаута.
   * */
  orderStarted(totalPrice?: number): void {
    this.carrotQuest.track('$order_started');
    localStorage?.setItem('LAST_CART_AMOUNT', (totalPrice ?? 0).toString());
  }

  /**
   * Подписка на рассылку промо.
   * Создает нового лида
   * @param segment
   * @param email
   */
  async subscribePromo(email: string, segment: string): Promise<void> {
    await this.transport.userProps({
      id: email,
      operations: [
        { op: 'update_or_create', key: '$email', value: email },
        { op: 'union', key: 'promo', value: segment },
      ],
    });
  }

  /**
   * Добавляет callback на event
   *
   * @param event
   * @param callback
   */
  addCallback(
    event: TCarrotEvents,
    callback: (data: TCarrotEventCallbackData) => void,
  ): void {
    this.carrotQuest.addCallback(event, callback);
  }

  /**
   * Удаляет callback на event
   *
   * @param event
   * @param callback
   */
  removeCallback(
    event: TCarrotEvents,
    callback: (data: TCarrotEventCallbackData) => void,
  ): void {
    this.carrotQuest.removeCallback(event, callback);
  }

  /**
   * Превращает модель CartPositions в TProductAction
   * @param position CartPositions
   * */
  private cartPositionToProductAction(
    position: CartPositions,
  ): TProductActionParams {
    return {
      $amount: position.totalValue.toString(),
      $img: position.product.previewImage?.src ?? '',
      $name: position.product.name,
      $url: window.location.href,
    };
  }

  /**
   * Превращает модель Product в TProductAction
   * @param product Product
   * @param price OfferPrice
   * */
  private productToProductAction(
    product: Product,
    price?: OfferPrice,
  ): TProductActionParams {
    return {
      $name: product.name,
      $amount: price?.priceValue.toFixed(0) ?? '',
      $img: product.previewImage?.src ?? '',
      $url: window.location.href,
    };
  }
}

export const carrotService: CarrotService = new CarrotService(new HttpClient());

import { HttpClient, HttpClientResponse, isHttpClientError } from 'lib';
import { NetworkError } from '../errors';
import { DeliveryAddress, Order, Profile, TOfferResponse } from '../models';

function cleanModel<T extends object>(model: T): object {
  const data = { ...model };

  Object.keys(data).forEach((key: string) => {
    if (data[key as keyof T] === undefined) {
      delete data[key as keyof T];
    }
  });

  return data;
}

export class ProfileTransport {
  constructor(private httpClient: HttpClient) {}

  async getProfile(): Promise<Profile | undefined> {
    try {
      const response = await this.httpClient.get<HttpClientResponse<object>>(
        '/profile',
      );
      return Profile.fromServer(response?.data?.data ?? {});
    } catch (error: unknown) {
      if (isHttpClientError(error)) {
        throw new NetworkError(
          `${error.message} => Не удалось получить профиль пользователя`,
        );
      }
    }
  }

  async getServerProfile(): Promise<Profile> {
    const response = await this.httpClient.get<HttpClientResponse<object>>(
      '/profile',
    );
    return Profile.fromServer(response?.data?.data ?? null);
  }

  async getOrders(): Promise<Array<Order>> {
    try {
      const response = await this.httpClient.get<HttpClientResponse<object>>(
        '/profile/orders',
      );
      const { data: ordersResponseData } = response;

      if (Array.isArray(ordersResponseData.data)) {
        const ordersResponseDataDeserialized = ordersResponseData.data.map(
          (order: TOfferResponse) => Order.fromServer(order),
        );

        return ordersResponseDataDeserialized;
      }
      return [];
    } catch (error: unknown) {
      if (isHttpClientError(error)) {
        throw new NetworkError(
          `${error.message} => Не удалось получить список заказов`,
        );
      }
    }

    return [];
  }

  async updateProfile(data: Profile): Promise<void> {
    try {
      const request = cleanModel(data.toServer());
      await this.httpClient.post('/profile/update', request);
    } catch (error: unknown) {
      if (isHttpClientError(error)) {
        throw new NetworkError(
          `${error.message} => Не удалось обновить профиль пользователя`,
        );
      }
    }
  }

  async addAddress(request: DeliveryAddress): Promise<void> {
    try {
      await this.httpClient.post('/profile/address/add', request.toServer());
    } catch (error: unknown) {
      if (isHttpClientError(error)) {
        throw new NetworkError(
          `${error.message} => Не удалось добавить новый адрес`,
        );
      }
    }
  }

  async updateAddress(request: DeliveryAddress): Promise<void> {
    try {
      await this.httpClient.post('/profile/address/update', request.toServer());
    } catch (error: unknown) {
      if (isHttpClientError(error)) {
        throw new NetworkError(`${error.message} => Не удалось обновить адрес`);
      }
    }
  }

  async removeAddress(id: number): Promise<void> {
    try {
      await this.httpClient.post('/profile/address/remove', { id });
    } catch (error: unknown) {
      if (isHttpClientError(error)) {
        throw new NetworkError(`${error.message} => Не удалось удалить адрес`);
      }
    }
  }
}

import { JsonName, deserialize } from 'tserialize';

export class OrganizationModel {
  /** Название компании */
  @JsonName() name!: string;
  /** ИНН */
  @JsonName() inn!: string;

  constructor(data: Partial<OrganizationModel>) {
    Object.assign(this, data);
  }

  static fromServer(data: unknown): OrganizationModel {
    return deserialize(data, this);
  }
}

export type TOrganizationModel = Omit<OrganizationModel, 'fromServer'>;

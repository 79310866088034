import { deleteCookie, getCookie, setCookie, hasCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

export class CookieService {
  static setValue(key: string, value: string, options: OptionsType = {}): void {
    setCookie(key, value, {
      ...options,
      encode: (currentValue: string) => currentValue,
    });
  }

  static getValue(key: string, options: OptionsType = {}): string {
    return (getCookie(key, options) as string) || '';
  }

  static hasValue(key: string): boolean {
    return hasCookie(key);
  }

  static removeValue(key: string, options: OptionsType = {}): void {
    deleteCookie(key, {
      ...options,
      ...(!!options && { domain: process.env.appUrl }),
    });
    deleteCookie(key, options);
  }
}

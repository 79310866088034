import { JsonNameReadonly, deserialize } from 'tserialize';

export class RegionModel {
  /** Id региона */
  @JsonNameReadonly('region_id') regionId!: string;

  /** Имя региона */
  @JsonNameReadonly() name!: string;

  static fromServer(data: unknown): RegionModel {
    return deserialize(data, this);
  }
}

import {
  deserialize,
  JsonArray,
  JsonNameReadonly,
  JsonStruct,
} from 'tserialize';
import { ImageModel } from './image.model';
import { OfferModel } from './offer.model';

/** Лейблы товара */
export class ProductLabel {
  @JsonNameReadonly() name!: string;

  @JsonNameReadonly() code!: string;

  /** Позволяет десериализовать модель ответа от бекенда */
  static fromServer(data: ProductLabel): ProductLabel {
    return deserialize(data, this);
  }
}

const hasLabel =
  (key: 'sale' | 'new' | 'bestseller') => (_: unknown, data: ProductDTO) =>
    !!data.labels.find(
      ({ code }: { name: string; code: string }) => code === key,
    );

/** Класс продукта */
export class Product {
  /** Id */
  @JsonNameReadonly() id!: number;

  /** Название продукта */
  @JsonNameReadonly() name!: string;

  /** Описание продукта */
  @JsonNameReadonly() description: string = '';

  /** Краткое описание продукта */
  @JsonNameReadonly('preview_text') previewText: string = '';

  /** Является ли продукт бестселлером */
  @JsonNameReadonly('labels', hasLabel('bestseller'))
  bestseller!: boolean;

  /** Является ли продукт новым */
  @JsonNameReadonly('labels', hasLabel('new')) isNew!: boolean;

  /** Входит ли продукт в распродажу */
  @JsonNameReadonly('labels', hasLabel('sale')) isSale!: boolean;

  /** Навигационный идентификатор, используется для ссылок по типу /product/id */
  @JsonNameReadonly() slug!: string;

  /** Закропленное изображение продукта */
  @JsonNameReadonly() thumbnail?: string;

  /** Информация о размерах в виде html */
  @JsonNameReadonly('size_table') sizeTableHTML: string = '';

  /** Название категории */
  @JsonNameReadonly('category_name') categoryName: string = '';

  /** Слаг категории */
  @JsonNameReadonly('category_slug') categorySlug: string = '';

  /** Доступен ли товар */
  @JsonNameReadonly('is_available') isAvailable!: boolean;

  /** Лейблы товара */
  @JsonArray(ProductLabel)
  labels: Array<ProductLabel> = [];

  /** Превью изображения */
  @JsonStruct(ImageModel, 'preview_image')
  previewImage!: ImageModel | null;

  /** Последнее обновление продукта */
  @JsonNameReadonly('updated_at') updatedAt?: string;

  /** Список предложений для продукта */
  @JsonArray(OfferModel) offers: Array<OfferModel> = [];

  /** Галерея */
  @JsonArray(ImageModel) images: Array<ImageModel> = [];

  /** Рекомендуемые товары */
  @JsonArray(Product) related: Array<Product> = [];

  /** Флаг: предзаказ */
  @JsonNameReadonly('pre_order') isPreOrder!: boolean;

  /** Флаг: Что товар доступен только по подписке */
  @JsonNameReadonly('subscription') subscription = false;

  @JsonNameReadonly('category_id') categoryId!: number;

  /** Товар доступен только авторизированным работникам Точки*/
  @JsonNameReadonly('only_employees') onlyEmployees!: boolean | null;

  constructor(data: Omit<Product, 'toServer'>) {
    Object.assign(this, data);
  }

  /** Позволяет десериализовать модель ответа от бекенда */
  static fromServer(data: Product): Product {
    return deserialize(data, this);
  }
}

type ProductDTO = {
  sale: boolean;
  bestseller: boolean;
  new: boolean;
  labels: Array<{ name: string; code: string }>;
};
export type TProductResponse = Omit<Product, 'fromServer'>;

export type TProductRequest = Pick<Product, 'id'>;

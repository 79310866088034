import {
  OrganizationModel,
  TOrganizationModel,
} from 'lib/models/organization.model';
import { HttpClient, HttpClientResponse } from '../http-client';

const DEFAULT_LIMIT_SIZE = 10;
export class OrganizationTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Поиск организации по ИНН
   */
  async search(
    inn: string,
    limit: number = DEFAULT_LIMIT_SIZE,
  ): Promise<Array<TOrganizationModel>> {
    try {
      const {
        data: { data },
      } = await this.httpClient.get<
        HttpClientResponse<Array<TOrganizationModel>>
      >('/organizations/search/', { params: { inn } });

      return (
        data
          ?.map((item: TOrganizationModel) =>
            OrganizationModel.fromServer(item),
          )
          ?.slice(0, limit) ?? []
      );
    } catch (error) {
      console.log(error);
    }

    return [];
  }
}

export const organizationTransport = new OrganizationTransport(
  new HttpClient(),
);

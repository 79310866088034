import {
  deserialize,
  JsonArray,
  JsonName,
  JsonNameReadonly,
  JsonStruct,
} from 'tserialize';

class ConformationData {
  /** Флаг принудительно */
  @JsonName() enforce!: boolean;

  /** Ссылка на страницу оплаты */
  @JsonName('confirmation_url') confirmationUrl!: string;

  /** Тип */
  @JsonName() type!: string;
}

class Amount {
  /** Сумма */
  @JsonName() value!: string;

  /** Валюта */
  @JsonName() currency!: string;
}

class Recipient {
  /**  */
  @JsonName() account_id!: string;

  /**  */
  @JsonName() gateway_id!: string;
}

class Metadata {
  /**  */
  @JsonName() order_id!: string;

  /**  */
  @JsonName() order_key!: string;
}

export class SendCertificateResponseData {
  /** Id оплаты */
  @JsonName() id!: string;

  /** Статус */
  @JsonName() status!: string;

  /** Описание */
  @JsonName() description!: string;

  /** Время создания */
  @JsonName('created_at') createdAt!: string;

  /** Информация для оплаты через Ю-кассу */
  @JsonStruct(ConformationData) confirmation!: ConformationData;

  /** Флаг оплачено */
  @JsonName() paid!: false;

  /** Флаг возвращаемо */
  @JsonName() refundable!: false;

  /** Флаг тест */
  @JsonName() test!: boolean;

  /** Сумма и валюта */
  @JsonStruct(Amount) amount!: Amount;

  /** Информация о получателе */
  @JsonStruct(Recipient) recipient!: Recipient;

  /** metadata */
  @JsonStruct(Metadata) metadata!: Metadata;
}

/** Класс запроса сертификата */
export class CertificateResponse {
  /** data */
  @JsonStruct(SendCertificateResponseData, 'data')
  data!: SendCertificateResponseData;

  /** Сообщение */
  @JsonNameReadonly() message!: null | string;

  /** Статус */
  @JsonNameReadonly() status!: boolean;

  /** Код */
  @JsonNameReadonly() code!: null | string;

  static fromServer(data: unknown): CertificateResponse {
    return deserialize(data, this) || [];
  }
}

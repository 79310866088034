import { JsonName, serialize } from 'tserialize';

export type TRegisterRequest = Omit<RegisterRequest, 'toServer'>;

/**
 * Модель запроса регистрации
 */
export class RegisterRequest {
  /** Email */
  @JsonName() email!: string;

  /** Имя */
  @JsonName('name') username!: string;

  /** Имя */
  @JsonName('last_name') lastName!: string;

  /** Пароль */
  @JsonName() password!: string;

  /** День рождения */
  @JsonName('birth_date', (rawValue: string) => {
    const [day, month, year] = rawValue?.replace(/_/g, '').split('.') ?? '';
    return [year, month, day].filter(Boolean).join('-') || null;
  })
  birthday!: string;

  /** Подтверждающий пароль */
  @JsonName('password_confirmation') passwordConfirmation!: string;

  constructor(data: TRegisterRequest) {
    Object.assign(this, data);
  }

  toServer(): RegisterRequest {
    return serialize(this) as RegisterRequest;
  }
}

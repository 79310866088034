import { JsonName, serialize } from 'tserialize';

export type TCertificateRequest = Omit<CertificateRequest, 'toServer'>;

/** Класс запроса сертификата */
export class CertificateRequest {
  /** ID сертификата */
  @JsonName('amount') amount!: number;

  /** От кого */
  @JsonName('from_email') fromEmail!: string;

  /** Способ доставки */
  @JsonName('delivery_method') deliveryMethod!: 'none' | 'email';

  /** Текст поздравления */
  @JsonName() message!: string;

  /** Для кого */
  @JsonName('to_email') toEmail?: string;

  /** Дата доставки */
  @JsonName('delivery_date', (rawValue: string) => {
    return rawValue?.replace(/(\d+).(\d+).(\d+)/, '$3-$2-$1') || null;
  })
  deliveryDate?: string;

  constructor(data: TCertificateRequest) {
    Object.assign(this, data);
  }

  toServer(): CertificateRequest {
    return serialize(this) as CertificateRequest;
  }
}

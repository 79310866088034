type TTimeZones = 'moscow';
type TOptions = {
  date?: string;
  timeZone?: TTimeZones;
};

// Маппинг временных зон в миллисекундах,
// для МСК +3 часа к UTC
const timeZoneMap: Record<TTimeZones, number> = {
  moscow: 60000 * 180,
};

export function getTimeWithoutUserTimeZone(options?: TOptions): number {
  const date = options?.date ? new Date(options?.date) : new Date();
  const targetTimeZone = options?.timeZone ? timeZoneMap[options?.timeZone] : 0;
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return date.getTime() - userTimezoneOffset + targetTimeZone;
}

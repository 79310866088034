import { JsonNameReadonly, JsonStruct, deserialize } from 'tserialize';
import { Profile } from './profile.model';

/**
 * Модель ответа логина
 */
export class LoginResponse {
  /** Токен */
  @JsonNameReadonly() token!: string;

  /** Профиль пользователя */
  @JsonStruct(Profile) user!: Profile;

  /** Время жизни токена */
  @JsonNameReadonly('expires_in') expiresIn!: number;

  static fromServer(data: unknown): LoginResponse {
    return deserialize(data, this);
  }
}

import {
  deserialize,
  JsonArray,
  JsonName,
  JsonNameReadonly,
  JsonStruct,
} from 'tserialize';

export type DeliveryType = 'boxberry' | 'cdek' | 'pony';

const deliveryNames: Record<DeliveryType, string> = {
  boxberry: 'Boxberry',
  cdek: 'СДЭК',
  pony: 'PonyExpress',
};

export class DeliveryPointLocation {
  @JsonName('lat', String, Number) lat!: number;
  @JsonName('lon', String, Number) lon!: number;
}

export class DeliveryPoint {
  @JsonName() id!: number;
  /**
   * Адрес пункта выдачи
   */
  @JsonName() address!: string;
  /**
   * Координаты
   */
  @JsonStruct(DeliveryPointLocation) location!: DeliveryPointLocation;
  /**
   * Название пункта выдачи
   */
  @JsonName() type!: DeliveryType;
  /**
   * Телефон
   */
  @JsonName() phone!: string;
  /**
   * График работы
   */
  @JsonName() schedule!: string;
  /**
   * Как пройти
   */
  @JsonName() trip!: string;

  /**
   * Название пункта выдачи
   */
  @JsonNameReadonly('type', (type: DeliveryType) => deliveryNames[type])
  name!: string;
}

export class DeliveryPointPagination {
  @JsonName() total!: number;
  @JsonName() count!: number;
  @JsonName() cursor!: string | null;
  @JsonName('max_per_page') maxPerPage!: number;
}
/**
 *  Модель ответа поиска по пунктам выдачи
 */
export class DeliveryPointSearch {
  /** Id типа */
  @JsonArray(DeliveryPoint, 'delivery_points')
  deliveryPoints!: Array<DeliveryPoint>;

  @JsonStruct(DeliveryPointPagination) pagination!: DeliveryPointPagination;

  static fromServer(data: unknown): DeliveryPointSearch {
    return deserialize(data, this);
  }
}

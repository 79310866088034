import { IS_PRODUCTION } from '_shared';

const YA_ID = 52951321;

export class MetricsService {
  static yaCounterGoal(goal: string): void {
    if (IS_PRODUCTION && window.ym) {
      window.ym(YA_ID, 'reachGoal', goal);
    }
  }
}

import { JsonName, serialize } from 'tserialize';

type TSubscriptionRequest = Omit<SubscriptionRequest, 'toServer'>;

export enum SubscriptionTag {
  SITE_FOOTER = 'site_footer',
  WOMAN_HANDS = 'Проект Руки женщины',
  MENU_10_SALE = 'menu-10-sale',
  FAKOSIMA_PRESALE = 'fakoshima-presale',
  FAKOSIMA_LOTTERY = 'fakoshima-lottery',
  SUPER_SOCKS = 'super-socks',
  ADMARGINEM = 'admarginem',
  POLKA = 'polka',
  PRESALE_ADMARGINEM = 'presale_admarginem',
  POPUP = 'pop-up',
  /** Подписка на предзаказ футболки Мамя я в банке */
  PREORDER_FUTB_MAMA = 'preorder-futb-mama',
  /** Подписка на новости на страницы черной пятницы */
  BLACK_FRIDAY_2023 = 'black-friday-2023',
  /** Подписка розышрыш носков */
  SUPER_SOCKS_PROMO = 'super-socks-priz',
  /** ДР Универмага 2023 год*/
  HB_2023 = 'hb-2023',
  BUSINESSDAY24 = 'businessday24',
}

export class SubscriptionRequest {
  /** E-mail для подписки */
  @JsonName() email!: string;

  /** ID листа для подписки */
  @JsonName() list!: string;

  /** URL страницы */
  @JsonName('page_url') pageUrl!: string;

  @JsonName('tags') tags?: SubscriptionTag;

  constructor(data: TSubscriptionRequest) {
    Object.assign(this, data);
  }

  /** Позволяет сериализовать запрос для бекенда */
  toServer(): TSubscriptionRequest {
    return serialize(this) as TSubscriptionRequest;
  }
}

import {
  ProductsResponseModel,
  TProductsListResponse,
} from 'lib/models/products.response';
import {
  SearchProductsRequest,
  TSearchProductsRequest,
} from 'lib/models/search-products.request';
import { HttpClient, HttpClientResponse } from '../http-client';
import { Product, ProductLabel, TProductResponse } from '../models';

/**
 * Транспорт продуктов
 */
export class ProductTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Поиск продуктов
   */
  async searchProducts(
    params: TSearchProductsRequest,
  ): Promise<Array<TProductResponse>> {
    try {
      const productsResponse = await this.httpClient.get<
        HttpClientResponse<{ products: Array<TProductResponse> }>
      >('/search/products', {
        params: new SearchProductsRequest(params).toServer(),
      });
      const { data: productsResponseData } = productsResponse;
      if (Array.isArray(productsResponseData.data?.products)) {
        const productsResponseDataDeserialized =
          productsResponseData.data.products.map((product: TProductResponse) =>
            Product.fromServer(product),
          );

        // https://github.com/vercel/next.js/issues/11993
        return JSON.parse(
          JSON.stringify(productsResponseDataDeserialized),
        ) as Array<TProductResponse>;
      }

      return [];
    } catch (error) {
      console.log(error);
    }

    return [];
  }

  /**
   * Получение списка всех продуктов
   */
  async getProducts(page: number = 1): Promise<TProductsListResponse | null> {
    try {
      const productsResponse = await this.httpClient.get<
        HttpClientResponse<Array<TProductsListResponse>>
      >('/products/', {
        params: { page },
      });
      const { data: productsResponseData } = productsResponse;

      return JSON.parse(
        JSON.stringify(ProductsResponseModel.fromServer(productsResponseData)),
      ) as TProductsListResponse;
    } catch (error) {
      console.log(error);
    }

    return null;
  }

  /**
   * Получение всех продуктов
   */
  async getAllProducts(): Promise<TProductsListResponse | null> {
    try {
      const productsResponse = await this.httpClient.get<
        HttpClientResponse<Array<TProductsListResponse>>
      >('/products/list');
      const { data: productsResponseData } = productsResponse;

      return JSON.parse(
        JSON.stringify(ProductsResponseModel.fromServer(productsResponseData)),
      ) as TProductsListResponse;
    } catch (error) {
      console.log(error);
    }

    return null;
  }

  /**
   * Получение продукта
   */
  async getProduct(slug: string): Promise<TProductResponse | null> {
    try {
      const productResponse = await this.httpClient.get<
        HttpClientResponse<TProductResponse>
      >(`/products/${slug}`);
      const { data: productResponseData } = productResponse;

      return productResponseData.data
        ? (JSON.parse(
            JSON.stringify(Product.fromServer(productResponseData.data)),
          ) as TProductResponse)
        : null;
    } catch (error) {
      console.log(`Не удалось запросить товар ${slug}`);
    }

    return null;
  }

  /**
   * Получение продуктов по лейблу
   */
  async getProductsByLabel(label: string): Promise<Array<TProductResponse>> {
    try {
      const productsResponse = await this.httpClient.get<
        HttpClientResponse<Array<TProductResponse>>
      >(`/products/label/${label}`);
      const { data: productsResponseData } = productsResponse;

      const products = productsResponseData.data.map(
        (product: TProductResponse) => Product.fromServer(product),
      );

      // https://github.com/vercel/next.js/issues/11993
      return JSON.parse(JSON.stringify(products)) as Array<TProductResponse>;
    } catch (error) {
      console.log(error);
    }

    return [];
  }

  /**
   * Получить список всех возможных лейблов
   */
  async getProductLabels(): Promise<Array<ProductLabel>> {
    try {
      const productsResponse = await this.httpClient.get<
        HttpClientResponse<Array<ProductLabel>>
      >(`/products/labels`);
      const { data: labelsResponseData } = productsResponse;
      const labels = labelsResponseData.data.map((label: ProductLabel) =>
        ProductLabel.fromServer(label),
      );
      return JSON.parse(JSON.stringify(labels)) as Array<ProductLabel>;
    } catch (error) {
      console.log(error);
    }

    return [];
  }
}

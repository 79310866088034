import { HttpClient, HttpClientResponse } from '../http-client';
import { TCaptchaResponse } from '../models';

export class GoogleTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Проверка капчи
   */
  async verifyCaptcha(token: string): Promise<TCaptchaResponse | null> {
    try {
      const { data } = await this.httpClient
        .post<TCaptchaResponse>(
          '/captcha/',
          { token },
          {
            baseURL: process.env.nextApiBaseUrl,
          },
        )
        .catch((error: Error) => ({
          data: {
            status: false,
            data: null,
            message: error.message ?? 'Непредвиденная ошибка',
          },
        }));

      return data;
    } catch (error) {
      console.log(error);
    }

    return null;
  }
}

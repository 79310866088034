import { JsonName } from 'tserialize';

/**
 * Модель изображения
 */
export class ImageModel {
  /** Путь до картинки */
  @JsonName() src!: string;

  /** Время обновления */
  @JsonName('updated_at') updatedAt!: string | null;

  /** Время создания */
  @JsonName('created_at') createdAt!: string | null;

  /** Название(использовать для alt) */
  @JsonName() title!: string;
}

import {
  deserialize,
  JsonArray,
  JsonName,
  JsonNameReadonly,
  serialize,
} from 'tserialize';
import { DeliveryAddress } from './delivery-address.model';
import { isTochkaEmail } from '../validators/validators';

export class Profile {
  /** Аватар */
  @JsonName() avatar!: string;

  /** Группы */
  @JsonName() groups!: Array<string>;

  /** Дополнительные свойства */
  @JsonName() property!: Array<string>;

  /** Время последнего обновления */
  @JsonName('updated_at') updatedAt!: Date;

  /** Время создания */
  @JsonName('created_at') createdAt!: Date;

  /** Id */
  @JsonName() id!: number;

  /** Имя */
  @JsonName('name') firstName!: string | null;

  /** Фамилия */
  @JsonName('last_name') lastName!: string | null;

  /** Отчество */
  @JsonName('middle_name') middleName!: string | null;

  /** Телефон */
  @JsonName() phone!: string | null;

  /** Список телефонных номеров */
  @JsonName('phone_list') phoneList!: Array<string>;

  /** Роль */
  @JsonName() role!: string;

  /** Адрес */
  @JsonArray(DeliveryAddress, 'address') addresses: Array<DeliveryAddress> = [];

  /** Активен или нет */
  @JsonName('is_activated') isActivated!: boolean;

  /** Адрес электронной почты */
  @JsonName() email!: string;

  /** Новый пароль */
  @JsonName() password?: string;

  /** Подтверждение нового пароля */
  @JsonName('password_confirmation') passwordConfirm?: string;

  /** Флаг: личная ссылка сотруднику Универмага */
  @JsonName('personal_discount') hasPersonalDiscount?: boolean;

  @JsonNameReadonly('email', (email: string) => isTochkaEmail(email))
  isEmployee!: boolean;

  @JsonNameReadonly('personal_discount', (value: boolean) => value ?? false)
  isUnivermagEmployee!: boolean;

  get fullName(): string {
    return [this.firstName, this.middleName, this.lastName]
      .filter(Boolean)
      .join(' ');
  }

  constructor(data: Omit<Partial<Profile>, 'toServer'>) {
    Object.assign(this, data);
  }

  toServer(): object {
    return serialize(this);
  }

  static fromServer(data: object): Profile {
    return deserialize(data, Profile);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PubSub<T extends string, P extends Array<any> = Array<any>> {
  private events = new Map<T, Array<(...args: P) => void>>();

  on(event: T, callback: (...args: P) => void): void {
    if (!this.events.has(event)) {
      this.events.set(event, []);
    }
    this.events.get(event)?.push(callback);
  }

  off(event: T, callback: (...args: P) => void): void {
    if (!this.events.has(event)) {
      return;
    }
    this.events.set(
      event,
      this.events
        .get(event)
        ?.filter((cb: (...args: P) => void) => cb !== callback) ?? [],
    );
  }

  emit(event: T, ...args: P): void {
    if (!this.events.has(event)) {
      return;
    }
    this.events.get(event)?.forEach((cb: (...args: P) => void) => cb(...args));
  }
}

import { HttpClient } from 'lib/http-client';
import { TProductsListResponse } from 'lib/models/products.response';
import { TSearchProductsRequest } from 'lib/models/search-products.request';
import {
  OfferModel,
  ProductLabel,
  PropertyModel,
  TProductResponse,
} from '../models';
import { ProductTransport } from '../transports';

const HIDDEN_PROPERTIES_NAMES = ['Цвет'];

export class ProductService {
  private transport: ProductTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new ProductTransport(httpClient);
  }

  async getBestSellerProducts(): Promise<Array<TProductResponse>> {
    return this.transport.getProductsByLabel('bestseller');
  }

  async getNewsProducts(): Promise<Array<TProductResponse>> {
    return this.transport.getProductsByLabel('new');
  }

  async getProducts(page: number = 1): Promise<TProductsListResponse | null> {
    return this.transport.getProducts(page);
  }

  async getAllProducts(): Promise<TProductsListResponse | null> {
    return this.transport.getAllProducts();
  }

  async searchProducts(
    params: TSearchProductsRequest,
  ): Promise<Array<TProductResponse>> {
    return this.transport.searchProducts(params);
  }
  async getProductsByLabel(label: string): Promise<Array<TProductResponse>> {
    return this.transport.getProductsByLabel(label);
  }

  async getProductLabels(): Promise<Array<ProductLabel>> {
    return this.transport.getProductLabels();
  }

  async getProduct(slug: string): Promise<TProductResponse | null> {
    return this.transport.getProduct(slug);
  }

  async getSale(): Promise<Array<TProductResponse>> {
    return this.transport.getProductsByLabel('sale');
  }

  async getNew(): Promise<Array<TProductResponse>> {
    return this.transport.getProductsByLabel('new');
  }

  private getAllPropertiesFromOffers(
    offers: Array<OfferModel>,
  ): Array<PropertyModel> {
    return offers.map((offer: OfferModel) => offer.property).flat();
  }

  private getUnicPropertiesNames(
    properties: Array<PropertyModel>,
  ): Array<string> {
    return Array.from(
      new Set(properties.map((property: PropertyModel) => property.name)),
    );
  }

  private getUnicPropertiesCodes(
    properties: Array<PropertyModel>,
  ): Array<string> {
    return Array.from(
      new Set(properties.map((property: PropertyModel) => property.code)),
    );
  }

  private getPropertiesByName(
    offers: Array<OfferModel>,
    name: string,
  ): Array<PropertyModel | undefined> {
    return offers.map((offer: OfferModel) =>
      offer.property.find((property: PropertyModel) => property.name === name),
    );
  }

  private getPropertiesByValue(
    offers: Array<OfferModel>,
    value: string,
  ): Array<PropertyModel | undefined> {
    return offers.map((offer: OfferModel) =>
      offer.property.find(
        (property: PropertyModel) => property.value === value,
      ),
    );
  }

  getPropertiesNames(offers: Array<OfferModel>): Array<string> {
    const allProperties = this.getAllPropertiesFromOffers(offers);
    const unicNames = this.getUnicPropertiesNames(allProperties);

    return unicNames.filter(
      (name: string) => !HIDDEN_PROPERTIES_NAMES.includes(name),
    );
  }

  getUnicPropertiesByValue(
    offers: Array<OfferModel>,
    name: string,
  ): Array<PropertyModel | undefined> {
    const propertiesByName = this.getPropertiesByValue(offers, name);

    return Array.from(
      new Map(
        propertiesByName.map((property: PropertyModel | undefined) => [
          property?.value,
          property,
        ]),
      ).values(),
    );
  }

  getUnicPropertiesByName(
    offers: Array<OfferModel>,
    name: string,
  ): Array<PropertyModel | undefined> {
    const propertiesByName = this.getPropertiesByName(offers, name);

    return Array.from(
      new Map(
        propertiesByName.map((property: PropertyModel | undefined) => [
          property?.value,
          property,
        ]),
      ).values(),
    );
  }

  getMapedPropertiesToCode(
    offers: Array<OfferModel>,
  ): Record<string, Array<PropertyModel>> {
    const map: Record<string, Array<PropertyModel>> = {};
    const properties = this.getAllPropertiesFromOffers(offers);

    const codes = this.getUnicPropertiesCodes(properties);

    for (const code of codes) {
      map[code] = [];
    }

    return map;
  }

  getAvailableOffers(
    offers: Array<OfferModel>,
    properties: Record<string, string>,
  ): Array<OfferModel> {
    const availableOffersFlags = offers.map((offer: OfferModel) =>
      offer.property.every((property: PropertyModel) => {
        const value = properties[property.code];
        if (value === '' || value === undefined) return true;

        return property.value === value;
      }),
    );

    return (
      offers
        .map((offer: OfferModel, index: number) =>
          availableOffersFlags[index] ? offer : undefined,
        )
        // ts не понимает, что в массиве после фильтра не будет undefined
        .filter(Boolean) as Array<OfferModel>
    );
  }

  filterProductsByAvailability(
    products: Array<TProductResponse> = [],
  ): Array<TProductResponse> {
    return products.filter((product: TProductResponse) => product.isAvailable);
  }
}

export const productService = new ProductService(new HttpClient());

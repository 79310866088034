import { MoneyFormatter } from 'lib/formatters';
import { JsonArray, JsonName, JsonNameReadonly, JsonStruct } from 'tserialize';
import { ImageModel } from './image.model';
import { PropertyModel } from './property-model.model';

export class OfferPrice {
  /** Текущая цена */
  @JsonName('price_value', undefined, (value: string) => parseFloat(value))
  priceValue!: number;

  /** Стараая цена */
  @JsonName('old_price_value', undefined, (value: string) => parseFloat(value))
  oldPriceValue!: number;

  get isPriceChanged(): boolean {
    return this.oldPriceValue !== 0 && this.oldPriceValue !== this.priceValue;
  }
}
export class OfferPrices {
  @JsonStruct(OfferPrice) regular!: OfferPrice;

  @JsonStruct(OfferPrice) employee!: OfferPrice;
}
/** Класс предложений для продукта */
export class OfferModel {
  /** Id */
  @JsonNameReadonly() id!: string;

  /** Название предложения */
  @JsonNameReadonly() name!: string;

  /** Название предложения */
  @JsonNameReadonly() description!: string;

  /** Название предложения */
  @JsonNameReadonly() code!: string;

  /** Цена предложения */
  @JsonStruct(OfferPrices) prices!: OfferPrices;

  /** Цена предложения */
  @JsonNameReadonly('price_value') priceValue!: number;

  /** Старая цена предложения */
  @JsonNameReadonly('old_price') oldPrice!: string;

  /** Старая цена предложения */
  @JsonNameReadonly('old_price_value') oldPriceValue!: number;

  /** Символьный код валюты */
  @JsonNameReadonly('currency_code') currencyCode!: MoneyFormatter.Currency;

  /** Количество предложений */
  @JsonNameReadonly() quantity!: number;

  /** Превью изображения */
  @JsonStruct(ImageModel, 'preview_image')
  previewImage!: ImageModel;

  /** Свойства */
  @JsonArray(PropertyModel) property: Array<PropertyModel> = [];
}

import { HttpClient } from 'lib/http-client';
import { DeliveryAddress, Order, Profile } from '../models';
import { ProfileTransport } from '../transports';

const PROFILE_STORAGE_KEY = 'user_profile';

export class ProfileService {
  private transport: ProfileTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new ProfileTransport(httpClient);
  }

  getProfile(): Promise<Profile | undefined | null> {
    return this.transport.getProfile();
  }

  getServerProfile(): Promise<Profile | null> {
    return this.transport.getServerProfile();
  }

  getOrders(): Promise<Array<Order>> {
    return this.transport.getOrders();
  }

  updateProfile(data: Partial<Profile>): Promise<void> {
    const profile: Profile = new Profile(data);

    return this.transport.updateProfile(profile);
  }

  addDeliveryAddress(data: DeliveryAddress): Promise<void> {
    return this.transport.addAddress(data);
  }

  removeDeliveryAddress(id: number): Promise<void> {
    return this.transport.removeAddress(id);
  }

  updateDeliveryAddress(data: DeliveryAddress): Promise<void> {
    return this.transport.updateAddress(data);
  }

  getProfileFromStorage(): Profile | null {
    const profile = localStorage.getItem(PROFILE_STORAGE_KEY);
    if (profile) {
      return Profile.fromServer(JSON.parse(profile) as object);
    }
    return null;
  }

  static setProfileToStorage(profile: Profile | null): void {
    localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(profile));
  }

  static isTochkaProfile(profile: Profile): boolean {
    return profile.email.includes('@tochka.com');
  }
}

export const profileService = new ProfileService(new HttpClient());

import { deserialize, JsonArray, JsonName } from 'tserialize';

export class Office {
  @JsonName() id!: number;
  /**
   * Город
   */
  @JsonName() city!: string;
  /**
   * Адрес
   */
  @JsonName() address!: string;

  /**
   * Адрес
   */
  get addressFull(): string {
    return `${this.city}, ${this.address}`;
  }
}

/**
 *  Модель ответа поиска по офисам
 */
export class OfficeResponse {
  /** Id типа */
  @JsonArray(Office)
  data!: Array<Office>;

  static fromServer(data: unknown): OfficeResponse {
    return deserialize(data, this);
  }
}

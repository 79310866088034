export const RUSSIAN_PHONE_REGEXP =
  /^(\+7|7|8)?[\s-]?\(?(\d{3})\)?[\s-]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})$/;

export const EMAIL_REGEXP =
  /^([A-Za-z0-9_\-.])+@([A-Za-zа-яА-Я0-9_\-.]){2,63}\.([A-Za-z]{2,6})$/;

export const ValidateEmail = (
  email: string | undefined,
  errorText?: string,
): string | undefined => {
  const rgx = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
  if (!email || !rgx.test(email)) return errorText || 'Неправильный email';
};

export const isTochkaEmail = (email: string | undefined): boolean => {
  return !!(email && email.includes('@tochka.com'));
};

export const ValidatePassword = (
  password: string | undefined,
  errorText?: string,
): string | undefined => {
  if (!password || password.length < 7 || password.length > 40) {
    return errorText || 'Пароль должен быть от 7 до 40 символов!';
  }
};

export const ValidateInequalityPassword = (
  password: string | undefined,
  passwordConfirmation: string | undefined,
  errorText?: string,
): string | undefined => {
  if (!password || !passwordConfirmation || password !== passwordConfirmation) {
    return errorText || 'Пароль и его подтверждение должны быть одинаковыми!';
  }
};

export const ValidateDate = (value: string | undefined = ''): boolean => {
  const [day, month, year] = value.split('.');
  const date = new Date(`${month}/${day}/${year}`);
  if (date.toString() === 'Invalid Date') {
    return false;
  }
  const isValidDay = date.getDate() === Number(day);
  const isValidMonth = date.getMonth() === Number(month) - 1;
  const isValidYear = date.getFullYear() === Number(year);
  return isValidDay && isValidMonth && isValidYear;
};

export const ValidateBirthDate =
  (options?: { required: boolean }) =>
  (value: string | undefined = ''): boolean => {
    if (!options?.required && (value === '' || value === '__.__.____')) {
      return true;
    }

    if (!ValidateDate(value)) {
      return false;
    }
    const [day, month, year] = value.split('.');
    const date = new Date(`${month}/${day}/${year}`);
    return date.getTime() <= Date.now();
  };

/** Время ревалидации - примерно +-15 минут  */
const STATIC_BASE_REVALIDATE_TIME = 10;
const SECONDS_IN_MIN = 60;

export const getRevalidateTime = (): number => {
  // Случайное размытие от -5 до 5
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const RANDOM_DELAY_MIN = Math.floor(Math.random() * 11) - 5;

  return (STATIC_BASE_REVALIDATE_TIME + RANDOM_DELAY_MIN) * SECONDS_IN_MIN;
};

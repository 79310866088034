import { CertificateRequest, TCertificateRequest } from 'lib';
import { HttpClient } from 'lib/http-client';
import { CertificateResponse } from '../models/certificate.response';
import { CertificatesResponse } from '../models/certificates.response';
import { CertificateTransport } from '../transports';

export class CertificateService {
  private transport: CertificateTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new CertificateTransport(httpClient);
  }

  async sendCertificate(
    data: TCertificateRequest,
  ): Promise<CertificateResponse> {
    const request = new CertificateRequest(data);

    return this.transport.sendCertificate(request.toServer());
  }

  async getCertificatesList(): Promise<CertificatesResponse | undefined> {
    return this.transport.getCertificatesList();
  }
}

export const certificateService = new CertificateService(new HttpClient());

import { JsonNameReadonly } from 'tserialize';

/**
 * Класс свойств
 */
export class PropertyModel {
  /**
   * Тип (размер, пол, цвет и тд.)
   */
  @JsonNameReadonly() code!: string;

  /**
   * Название свойства
   */
  @JsonNameReadonly() name!: string;

  /**
   * Значение свойства
   */
  @JsonNameReadonly() value!: string;

  /**
   * Описание свойства
   */
  @JsonNameReadonly() description!: string;
}

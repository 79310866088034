import { deserialize, serialize, JsonName } from 'tserialize';

enum AddressType {
  PAYMENT = '0',
  DELIVERY = '1',
}

export class DeliveryAddress {
  @JsonName() id?: number;

  /** Регион/область */
  @JsonName() state!: string;

  /** Населенный пункт */
  @JsonName() city!: string;

  /** Улица, номер дома и квартиры */
  @JsonName('address1') address1!: string;

  /** Полный адрес из справочника */
  @JsonName('address_full') addressFull!: string;

  /** Имя получателя */
  @JsonName('first_name') firstName!: string;

  /** Фамилия получателя */
  @JsonName('last_name') lastName!: string;

  /** Индекс получателя */
  @JsonName('postcode') postcode!: string;

  /**
   * Тип адреса (адрес доставки или адрес оплаты)
   *
   * В данный момент поле не используется и нужно только
   * для корректной отправки формы
   */
  @JsonName() type: string = AddressType.DELIVERY;

  constructor(data: Omit<Partial<DeliveryAddress>, 'toServer'>) {
    Object.assign(this, data);
  }

  get recipient(): string {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`;
  }

  /** @deprecated use addressFull */
  get fullAddress(): string {
    return `${this.address1} ${this.city} ${this.state}`;
  }

  toServer(): object {
    return serialize(this);
  }

  static fromServer(data: object): DeliveryAddress {
    return deserialize(data, DeliveryAddress);
  }
}
